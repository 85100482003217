/* style for game.js */

.headerbufferreplay {
  height: 3vh;
  height: 3dvh;
}

.chessboard {
  width: 64vh;
  width: 64dvh;
  height: 64vh;
  height: 64dvh;
}

.chessboardvert {
  width: 100%;
  aspect-ratio: 1/1;
  margin-left: auto;
  margin-right: auto;
}

.chessboardreplayvert {
  width: 100%;
  aspect-ratio: 1/1;
  margin-left: auto;
  margin-right: auto;
}

.metabox {
  width: 32vh;
  width: 32dvh;
  min-width: 15rem;
  height: 100%;
  background-color: #386cb0;
}

.gamecontainer {
  width: 96vh;
  width: 96dvh;
  height: 70.6vh;
  height: 70.6dvh;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.gamecontainerreplay {
  width: 90.8vh;
  width: 90.8dvh;
  height: 70.6vh;
  height: 70.6dvh;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.resourcebar {
  width: 64vh;
  width: 64dvh;
  height: 6.4vh;
  height: 6.4dvh;
  display: flex;
  background-color: rgba(0, 0, 255, 0.2);
}

.resourcebarreplay {
  width: 58.8vh;
  width: 58.8dvh;
  aspect-ratio: 10/1;
  display: flex;
  background-color: rgba(0, 0, 255, 0.2);
}

.resourcebarvert {
  width: 100%;
  aspect-ratio: 10/1;
  display: flex;
  background-color: rgba(0, 0, 255, 0.2);
  margin-left: auto;
  margin-right: auto;
}

.resourcebarreplayvert {
  width: 100%;
  aspect-ratio: 10/1;
  display: flex;
  background-color: rgba(0, 0, 255, 0.2);
  margin-left: auto;
  margin-right: auto;
}

.resource {
  width: calc(10% - 4px);
  height: calc(100% - 4px);
  float: left;
  border-style: solid;
  border-width: 2px;
  border-color: #000000;
}

.resourceinner {
  width: 100%;
  height: 100%;
  background-color: #a0a;
  animation-name: fadeinresource;
  animation-fill-mode: both;
  animation-timing-function: linear;
}

.info {
  width: 90%;
  height: 2rem;
  margin: 5%;
  padding: 2%;
  box-sizing: border-box;
  font-size: 1rem;
}

.infovert {
  height: 2rem;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
}

.online {
  background-color: #ccebc5;
}

.offline {
  background-color: #fbb4ae;
}

.ready {
  width: 90%;
  height: 4rem;
  margin-left: 5%;
  margin-right: 5%;
  font-size: 1.5rem;
}

.readyvert {
  height: 2rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  min-width: 7.5rem;
}

.console {
  width: 88%;
  height: min(calc(59.4vh - 18rem), calc(70.6vh - 23.25rem));
  height: min(calc(59.4dvh - 18rem), calc(70.6dvh - 23.25rem));
  background-color: lightgray;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;
  padding: 1%;
  font-size: 1.3rem;
  font-style: italic;
  text-align: left;
  color: #555;
  overflow: auto;
  word-wrap: break-word;
}

.consolereplay {
  width: 88%;
  height: min(calc(59.4vh - 18rem), calc(70.6vh - 23.25rem));
  height: min(calc(59.4dvh - 18rem), calc(70.6dvh - 23.25rem));
  background-color: lightgray;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;
  padding: 1%;
  font-size: 1.3rem;
  font-style: italic;
  text-align: left;
  color: #555;
  overflow: auto;
  word-wrap: break-word;
}

.consolevert {
  margin-left: auto;
  margin-right: auto;
  width: 90vw;
  width: 90dvw;
  background-color: lightgray;
  overflow: auto;
}

.consoleinner {
  margin: 5px;
}

.gamectrl {
  width: 90%;
  margin: 5%;
  height: 4rem;
  display: flex;
}

.controlbutton {
  width: 4rem;
  height: 4rem;
  background-color: darkgray;
  font-size: 1.5rem;
  position: relative;
}

.draw {
  margin-left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.draw:hover {
  background-color: #669922;
  color: #eee;
}

.resign {
  margin-left: auto;
  margin-right: 5%;
}

.resign:hover {
  background-color: #ee5511;
  color: #eee;
}

.infoRow {
  width: 100%;
  height: 2rem;
  margin-top: 1%;
  margin-bottom: 1%;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.infoRowReplay {
  width: 100%;
  height: 2rem;
  margin-top: 1%;
  margin-bottom: 1%;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.ctrlRow {
  width: min(90vw, calc(65vh - 9rem));
  width: min(90dvw, calc(75dvh - 9rem));
  height: 4rem;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.ctrlRowReplay {
  width: 80%;
  height: 4rem;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.chat_input {
  width: calc(90% - 5px);
  margin-bottom: 5%;
  font-size: 1.3rem;
}

.text_input_wrapper {
  text-align: center;
}

.gameOverMessage {
  font-size: max(6vh, 1rem);
  color: #a61c00;
  text-align: center;
}

@keyframes fadeinresource {
  0% {
    opacity: 0;
    width: 0%;
  }
  1% {
    opacity: 0.1;
    width: 1%;
  }
  99% {
    opacity: 0.5;
    width: 99%;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0.1;
  }
  99% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

#loginBox {
  margin-left:auto;
  margin-right:auto;
  width: 50%;
  height: 50vh;
  min-width: 350px;
  max-width: 400px;
  background-color: lightgray;
}

.button_input_row {
  height: 4rem;
  display: flex;
  margin-top: auto;
}

.button_input {
  flex: 1;
  font-size: 1rem;
  margin: 0.5rem;
}

.replayBarContainer {
  width: 90vh;
  height: 3vh;
  margin-top: 2vh;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
  position: relative;
}

.replayBarContainervert {
  width: 90%;
  height: 2vh;
  margin-top: 1vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1vh;
  border: 1px solid black;
  position: relative;
}

.replayBarBackground {
  width: 100%;
  height: 100%;
  background-color: gray;
}

.replayBarProgress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #cc0000;
  animation: expand;
  animation-fill-mode: both;
  animation-timing-function: linear;
  pointer-events: none;
}

@keyframes expand {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.replayBarPointer {
  position: absolute;
  top: 0;
  height: 100%;
  aspect-ratio: 1 / 1;
  background-color: #008866;
  transform: translate(-50%, 0%) rotate(0.125turn);
  animation: slide;
  animation-fill-mode: both;
  animation-timing-function: linear;
  pointer-events: none;
}

@keyframes slide {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}

.middle {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.playimg {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 100%;
}

.replayBox {
  width: calc(100% - 6vh);
  height: 80vh;
  height: 80dvh;
  border-left: 3vh #cab2d6 solid;
  border-right: 3vh #cab2d6 solid;
  border-bottom: 3vh #cab2d6 solid;
}

.chessboardreplay {
  width: 58.8vh;
  width: 58.8dvh;
  aspect-ratio: 1/1;
}

.contentcontainervert {
  margin-left: auto;
  margin-right: auto;
  width: min(90vw, calc(65vh - 9rem));
  width: min(90dvw, calc(75dvh - 9rem));
}

.contentcontainerreplayvert {
  margin-left: auto;
  margin-right: auto;
  width: min(90vw, calc(53.5vh - 6.7rem));
  width: min(90dvw, calc(63.5dvh - 6.7rem));
}
